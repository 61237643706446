/*
* @Author: abhishek714
* @Date:   2024-10-07 16:48:39
* @Last Modified by:   abhi
* @Last Modified time: 2024-10-18 19:25:35
*/
import React from 'react';
import moment from 'moment';
import {Link,Redirect} from 'react-router-dom';
import uploadImageIcon from '../../images/assets/image-plus.webp';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';
import loading_icon from '../../images/loading.gif';

class OrderHistory extends React.Component{
	constructor(props){
		super(props);
		this.state ={
			fields: {},
            errors: {},
            success_redirect:0,
            order_history:{},
            filtered_order_history:{},
            showLoader:1,
            searchQuery: ''
		}
		this.userService = new UserService();
		this.filterOrders = this.filterOrders.bind(this);
		this.handleClearInput = this.handleClearInput.bind(this);
	}

	componentDidMount(){
		if (
      localStorage.getItem('techtop_user_id') != '' &&
      localStorage.getItem('techtop_user_id') != null
    ) {
      this.setState({ user_id: localStorage.getItem('techtop_user_id') });
      this.setState({
        first_name: localStorage.getItem('techtop_user_first_name'),
      });
      this.setState({
        last_name: localStorage.getItem('techtop_user_last_name'),
      });
      this.setState({
        company: localStorage.getItem('techtop_user_company'),
      });
      this.setState({ company_logo: localStorage.getItem('techtop_user_company_logo')});
      this.setState({ phone: localStorage.getItem('techtop_user_phone')});
      this.setState({ cell_phone: localStorage.getItem('techtop_user_cell_phone')});
      this.setState({ direct_phone: localStorage.getItem('techtop_user_direct_phone')});
      this.setState({ fax: localStorage.getItem('techtop_user_fax')});
      this.setState({ address: localStorage.getItem('techtop_user_address')});
      this.setState({ state: localStorage.getItem('techtop_user_state')});
      this.setState({ city: localStorage.getItem('techtop_user_city')});
      this.setState({ zip: localStorage.getItem('techtop_user_zip')});
      this.setState({ is_login: 1 });
      this.getOrderHistory(localStorage.getItem('techtop_user_company'));
    }

	}

	getOrderHistory(company=null) {
		company = (company === null) ? this.state.company : company;
		this.userService.getOrderHistory({company:company}).then(response => {
			this.setState({showLoader:0});
			if(response.status===200) {
				if(response.data.response==='fail') {

				} else {
					this.setState({order_history:response.data.result.order_history})
					this.setState({filtered_order_history: response.data.result.order_history})
				}
			}
		});
	}

	handleSearchInput = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    this.setState({ searchQuery: searchQuery }, () => {
      //this.filterOrders();
    });
  };

	filterOrders() {
    const { searchQuery, order_history } = this.state;

    if (!searchQuery) {
      // If the search query is empty, display the entire list
      this.setState({ filtered_order_history: order_history });
    } else {
      // Perform the filtering based on the search query
      const filtered = order_history.filter((order) => {
        const dateEntered = moment(order.date_entered).format('MM/DD/YYYY');
        const purchaseOrder = order.customer_po?.toLowerCase() || '';
        const salesOrder = order.so_number?.toLowerCase() || '';
        const partNumber = order.model?.toLowerCase() || '';

        return (
          dateEntered.includes(searchQuery) ||
          purchaseOrder.includes(searchQuery) ||
          salesOrder.includes(searchQuery) ||
          partNumber.includes(searchQuery)
        );
      });

      this.setState({ filtered_order_history: filtered });
    }
  }

	renderOrderHistory() {
		let { filtered_order_history, showLoader } = this.state;
		if(showLoader) {
			return(
				<React.Fragment>
					<tr>
						<td colSpan="8" style={{ textAlign: 'center' }}>
							<img src={loading_icon}/>
						</td>
					</tr>
				</React.Fragment>
			)
		} else {
			if(Object.keys(filtered_order_history).length === 0) {
				return(
					<React.Fragment>
						<tr>
							<td colSpan="8" style={{ textAlign: 'center' }}>
								No data found.
							</td>
						</tr>
					</React.Fragment>
				)
			} else {
				return (
            <React.Fragment>
                {filtered_order_history.map((order, index) => (
                    <tr key={index}>
                        <td>{moment(order.date_entered).format('MM/DD/YYYY')}</td>
                        <td>{order.customer_po}</td>
                        <td>{order.so_number}</td>
                        <td>{order.motor ? (<a href={`/motors/${order.model}`} target="_blank">
													      {order.model}
													    </a>) : order.model}</td>
                        <td>{order.motor && order.motor.group}
												</td>
                        <td>{order.qty_ordered}</td>
                        <td>{order.price}</td>
                        <td>{order.price}</td>
                    </tr>
                ))}
            </React.Fragment>
        );
			}
		}
		
	}

	handleClearInput = (event) => {
	  const searchQuery = event.target.value;
	  this.setState({ searchQuery }, () => {
	    if (searchQuery === "") {
	      // If the input is cleared, reset the filtered orders to original order history
	      this.setState({ filtered_order_history: this.state.order_history });
	    } else {
	      this.filterOrders(); // Filter based on current query
	    }
	  });
	};

	render() {
		return(
			<React.Fragment>
				<div className="middle-div">
					<section className="order-history-section">
						<div className="container">
							<div className="row">
								<div className="col-md-12">
									<div className="default-title">
										<div className="row">
											<div className="col-md-6">	
												<h1 className="m-0 color1">Order History</h1>
											</div>
											<div className="col-md-6">	
												<h2 className="m-0 color1 text-end">{this.state.company}</h2>
											</div>
										</div>
									</div>
								</div>	
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="common-width-div">
										<div className="row">
											<div className="col-md-12">
												<div className="input-group search-div">
													<input type="search" className="form-control rounded" placeholder="Search..." value={this.state.searchQuery} onChange={this.handleSearchInput} onInput={this.handleClearInput}/>
												</div>
											</div>
										</div>	
										<div className="row">
											<div className="col-md-12">	
												<div className="table-responsive mt-3 order-history-table">
													<table className="table">
														<thead>
															<tr>
																<th><span>Date</span></th>
																<th><span>Purchase Order #</span></th>
																<th><span>Sales Order #</span></th>
																<th><span>Model #</span></th>
																<th><span>Description</span></th>
																<th><span>Qty</span></th>
																<th><span>Unit Price</span></th>
																<th><span>Total Price</span></th>
															</tr>
														</thead>
														<tbody>
															{this.renderOrderHistory()}
													  </tbody>	
													</table>	
												</div>
											</div>
										</div>		
									</div>
								</div>	
							</div>			
						</div>
					</section>
				</div>
			</React.Fragment>
		);
	}

}

export default OrderHistory;