/*
* @Author: Abhi Bhatt
* @Date:   2020-07-15 11:53:52
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-06 12:39:46
*/

import React from 'react';
import {Link} from 'react-router-dom';
import ProductService from './../../services/ProductService/ProductService';
import SideBar from '../../components/SideBar/SideBar';

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class ProductInventory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			product_detail:{},
			motor_inventory:{},
			inventory_places:{}
		}
		this.productService = new ProductService();
		this.renderProductDetail = this.renderProductDetail.bind(this);
		this.renderInventoryTableHead = this.renderInventoryTableHead.bind(this);
	}

	componentDidMount() {
		const permalink = this.props.match.params.permalink;
		this.productService.getProductInventory(permalink).then(response=>{
			if(response.status===200){
				this.setState({product_detail:response.data.result.products[0].fields});
				this.setState({motor_inventory:response.data.result.motor});
				this.setState({inventory_places:response.data.result.inventory_places});
			}
		});
	}

	renderInventoryTableHead() {
		let inventory_places = this.state.inventory_places;
		let motor_inventory = this.state.motor_inventory;
		if(inventory_places && inventory_places.length>0) {
			return(
				<React.Fragment>
					<table className="table mt-5">
						<thead>
							<tr>
								<th>Model Number</th>
								{Object(inventory_places).map(function(value,key){
									return (<React.Fragment key={key}><th>{value.fields.name}</th></React.Fragment>)
								})}
							</tr>
						</thead>
						<tbody>
							{(motor_inventory && motor_inventory.length>0)?
								Object(motor_inventory).map(function(value1,key1){
									return(
										<React.Fragment key={key1}>
											<tr>
												<th><Link to={'/motors/'+value1.motor[0].fields.part_number}>{value1.motor[0].fields.part_number}</Link></th>
												{Object(value1.inventory_places).map(function(value2,key2){
													return(<React.Fragment key={key1+'-'+key2}><th>{value2.fields.count}</th></React.Fragment>)
												})}
											</tr>
										</React.Fragment>
									)
								})
							:''}

						</tbody>
					</table>
				</React.Fragment>
			)
		}

	}

	renderProductDetail() {
		let product_detail = this.state.product_detail;
		return(
			<React.Fragment>
				<div className="row mb-4">
					<div className="categoriesBox">
						<div className="col-md-12">
							<article className="product-details">
								<Link to="">
									<div className="row">
										<div className="col-md-12">
											<h3>{product_detail.name}</h3>
										</div>									
									</div>	
								</Link>
							</article>	
						</div>	
						<div className="col-md-12 mt-3">
							<article className="product">
								<div className="row">
									<div className="col-md-12">
										{renderHTML(product_detail.description)}
										
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

	render() {
		let product_detail = this.state.product_detail;
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
					<section className="product-area my-1">		
						<div className="row">
							<div className="col-md-12">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href={"/"}>Home</a><i className="fas fa-angle-right"></i></li>
									<li className="breadcrumb-item"><Link to={"/products/"}>Products</Link><i className="fas fa-angle-right"></i></li>
									<li className="breadcrumb-item"><Link to={"/categories/"+product_detail.category_permalink}>{product_detail.category_name}</Link><i className="fas fa-angle-right"></i></li>
									<li className="breadcrumb-item active">{product_detail.name}</li>
								</ol>
							</div>
						</div>		
						<div className="row">
							<div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12">
								<SideBar/>
							</div>
							<div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
								<div className="products">
									{this.renderProductDetail()}
									<div className="row">
										<div className="col-md-12 mb-4">
											<article className="motors">
												<p><Link to={"/products/"+product_detail.permalink}>Show Specs Table</Link></p>
												{this.renderInventoryTableHead()}
											</article>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>	
					</div>	
				</main>
			</React.Fragment>
		);
	}

}

export default ProductInventory;