import React, { Component } from 'react';
import Header from './components/Header/Header';
import UserHeader from './components/Header/UserHeader';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import { urlPath } from './constants.js';
import './App.css';
import techtopLogo from './images/techtop-full-logo.svg';
import keyIcon from './images/key.svg';

class App extends Component {
  key = 'Clave.123!!!';
  timeoutDuration = 43200000; // 12 hours in milliseconds

  state = {
    isAuthorized: false,
  };

  componentDidMount() {
    const savedKey = atob(sessionStorage.getItem('accessKey'));
    const savedTime = parseInt(sessionStorage.getItem('accessKeyTime'), 10);

    if (savedKey === this.key && savedTime && Date.now() - savedTime < this.timeoutDuration) {
      this.setState({ isAuthorized: true });
      this.loadStyles();
    } else {
      sessionStorage.removeItem('accessKey');
      sessionStorage.removeItem('accessKeyTime');
    }
  }

  loadStyles = async () => {
    if (
      urlPath === 'user/my-account' ||
      urlPath === 'user/order-history' ||
      urlPath === 'user/open-orders'
    ) {
      await import('./css/user/custom.css');
      await import('./css/user/responsive.css');
    } else {
      await import('./css/custom.css');
      await import('./css/responsive.css');
    }
  };

  handleKeySubmit = (event) => {
    event.preventDefault();
    const inputKey = event.target.elements.keyInput.value;

    if (inputKey === this.key) {
      sessionStorage.setItem('accessKey', btoa(inputKey));
      sessionStorage.setItem('accessKeyTime', Date.now().toString());
      this.setState({ isAuthorized: true }, this.loadStyles);
    } else {
      alert('The Access Key you entered is incorrect. \nPlease try again.');
    }
  };

  render() {
    if (!this.state.isAuthorized) {
      return (
        <div className="App authorization">
          <div className="card">
            <img className="logo" src={techtopLogo} alt="Techtop Logo" />
            <h1>Welcome</h1>
            <form onSubmit={this.handleKeySubmit}>
              <label htmlFor="keyInput">
                <img src={keyIcon} alt="Key Icon" /> Please enter the Access Key to continue
              </label>
              <input
                id="keyInput"
                type="password"
                name="keyInput"
                placeholder="Enter the Access Key"
                required
              />
              <button type="submit"> Unlock Access </button>
            </form>
          </div>
        </div>
      );
    }

    return (
      <div className="App">
        {urlPath === 'user/my-account' ||
        urlPath === 'user/order-history' ||
        urlPath === 'user/open-orders' ? (
          <UserHeader />
        ) : (
          <Header />
        )}
        <Main />
        <Footer />
      </div>
    );
  }
}

export default App;
