/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-23 15:38:25
 * @Last Modified by:   abhi714
 * @Last Modified time: 2024-09-19 18:39:12
 */
import { Link } from 'react-router-dom';
import ProductService from './../../services/ProductService/ProductService';
import SideBar from '../../components/SideBar/SideBar';
import ProductTable from '../../components/ProductTable/ProductTable';
import SearchByPartNumber from '../../components/SearchByPartNumber/SearchByPartNumber';
import React from 'react';
import './Product.css';

const renderHTML = (rawHTML) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product_detail: [], // title and description
      user_id: '',
      is_login: 0,
      filtered_products: [],
      loading: false,
    };
    this.productService = new ProductService();
    this.renderProductDetail = this.renderProductDetail.bind(this);
  }

  async componentDidMount() {
    this.setLoginStatus();
    await this.fetchProducts();
  }

  async fetchProducts() {
    const permalink = this.props.match.params.permalink;
    this.setState({ filtered_products: [], loading: true, product_detail: [] });
    try {
      const request = await this.productService.getProductDetail(permalink);
      if (request.status === 200) {
        // Set the product detail (title and description).
        // Description is in html format (also contains images).
        this.setState({
          product_detail: request.data.result.product_details,
        });

        // Set the motors data.
        const filtered_products = request.data.result.filtered_products || [];
        if (filtered_products?.length > 0) {
          this.setState({ filtered_products: filtered_products });
        }
      } else {
        console.error('Error: ', request);
      }
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.setState({ loading: false });
    }
  }

  setLoginStatus() {
    if (
      localStorage.getItem('techtop_user_id') != '' &&
      localStorage.getItem('techtop_user_id') != null
    ) {
      this.setState({ is_login: 1 });
      this.setState({ user_id: localStorage.getItem('techtop_user_id') });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const permalink = this.props.match.params.permalink;
    if (permalink !== prevProps.match.params.permalink) {
      await this.fetchProducts();
    }
  }
  renderProductDetail() {
    let product_detail = this.state.product_detail;
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="categoriesBox">
            <div className="col-md-12">
              <article className="product-details">
                <Link to="">
                  <div className="row">
                    <div className="col-md-12">
                      <h3>{product_detail.name}</h3>
                    </div>
                  </div>
                </Link>
              </article>
            </div>
            <div className="col-md-12 mt-3">
              <article className="product">
                <div className="row">
                  <div className="col-md-12">
                    {renderHTML(product_detail.description)}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  setFilteredProducts = (products) => {
    this.setState({ filtered_products: products, product_detail: [] });
  };

  renderFilteredProducts() {
    let filtered_products = this.state.filtered_products || [];
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 mb-4">
            <article className="motors">
              <div className="table-responsive">
                <table className="table mt-5">
                  <thead>
                    <tr>
                      <th>Model Number</th>
                      <th>Motor Group</th>
                      <th>Frame Size</th>
                      <th>HP</th>
                      <th>RPM</th>
                      <th>Voltage</th>
                      <th>Enclosure</th>
                      <th>Price</th>
                      <th>Multiplier Code</th>
                      {this.state.is_login === 1 ? <th>Inventory</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {filtered_products.length > 0 ? (
                      filtered_products.map((product, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              target="_blank"
                              className="link-with-icon"
                              to={`/motors/${product.num}`}
                            >
                              {product.num}
                            </Link>
                          </td>
                          <td>
                            {product.customFields['MGN- Product Line']
                              ? product.customFields[
                                  'MGN- Product Line'
                                ].replace(/^MGN-\s?[A-Za-z]?\s?-?\s?/, '')
                              : ''}
                          </td>
                          <td>{product.customFields['MGN- Frame']}</td>
                          <td>
                            {product.customFields['MGN- HP']
                              ? product.customFields['MGN- HP'].replace(
                                  /^MGN-\s?[A-Za-z]?\s?-?\s?/,
                                  ''
                                )
                              : ''}
                          </td>
                          <td>
                            {product.customFields['MGN- Pole/RPM']
                              ? product.customFields['MGN- Pole/RPM'].replace(
                                  /^MGN-\s?[A-Za-z]?\s?-?\s?/,
                                  ''
                                )
                              : ''}
                          </td>
                          <td>
                            {product.customFields['MGN- Voltage']
                              ? product.customFields['MGN- Voltage'].replace(
                                  /^MGN-\s?[A-Za-z]?\s?-?\s?/,
                                  ''
                                )
                              : ''}
                          </td>
                          <td>{product.customFields['Enclosure']}</td>
                          <td className="price">
                            {parseFloat(product.price).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </td>
                          <td>{product.customFields['Multiplier Code']}</td>
                          {this.state.is_login === 1 ? (
                            <td className="inventory">
                              <Link
                                target="_blank"
                                className="link-with-icon"
                                to={`/motors/${product.num}/inventory`}
                              >
                                inventory
                              </Link>
                            </td>
                          ) : null}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="8"
                          className="text-center font-weight-bold"
                        >
                          No products available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    let is_login = this.state.is_login;
    let product_detail = this.state.product_detail;
    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <section className="product-area my-1">
              <div className="row">
                <div className="col-md-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href={'/'}>Home</a>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={'/products/'}>Products</Link>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item active">
                      {product_detail.name}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12 mt-3">
                  <SearchByPartNumber
                    className="mb-3"
                    sendProducts={this.setFilteredProducts}
                  />
                  <div className="d-flex text-center fw-bold text-secondary">
                    <div className="separator-line"></div>
                    <span className="mx-3"> OR </span>
                    <div className="separator-line"></div>
                  </div>
                  <SideBar sendProducts={this.setFilteredProducts} />
                </div>
                <div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
                  <div className="products">
                    {product_detail?.name &&
                      product_detail?.description &&
                      this.renderProductDetail()}
                    {this.state.loading ? (
                      <div className="bg spinner gap-2">
                        <div className="loader"></div>
                        <span className="fw-bold">Loading...</span>
                      </div>
                    ) : (
                      <ProductTable
                        products={this.state.filtered_products}
                        is_login={is_login}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Product;
