import React, { useState, useEffect, useCallback } from 'react';
import CategoryService from './../../services/CategoryService/CategoryService';
import SideBarAccordion from '../SideBarAccordion/SideBarAccordion';
import multiChevronIconSvg from '../../images/assets/multi-chevron.svg';
import './SideBar.css';

const SideBar = ({sendProducts}) => {
  const [categoryData, setCategoryData] = useState({});
  const [selectedParams, setSelectedParams] = useState({});
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const categoryService = React.useMemo(() => new CategoryService(), []);
  const [isCollapsedAll, setIsCollapsedAll] = useState(true);

  const customFields = [
    'MGN- Product Line',
    'MGN- Frame',
    'MGN- HP',
    'MGN- Voltage',
    'MGN- Pole/RPM',
    'Enclosure',
  ];

  // Fetch initial category data on mount
  useEffect(() => {
    getAvailableParams();
  }, []);

  const getAvailableParams = async () => {
    try {
      const response = await categoryService.getAvailableParams();
      if (response.status === 200) {
        sortData(response.data);
        setCategoryData(response.data);
      } else {
        console.error('Error fetching category data:', response);
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  const handleCheckboxChange = useCallback(async (category, value, checked) => {
    const updatedParams = { ...selectedParams };
    let newSelectedCustomFields = [...selectedCustomFields];

    if (checked) {
      if (!updatedParams[category]) {
        updatedParams[category] = [];
        setSelectedCustomFields([...selectedCustomFields, category]);
        newSelectedCustomFields = [...selectedCustomFields, category];
      }
      updatedParams[category].push(value);
    } else {
      updatedParams[category] = updatedParams[category].filter(
        (item) => item !== value
      );
      if (updatedParams[category].length === 0) {
        delete updatedParams[category];
        // remove category from selectedCustomFields
      }
      newSelectedCustomFields = selectedCustomFields.filter(
        (field) => field !== category
      );
      setSelectedCustomFields(newSelectedCustomFields);
    }

    setSelectedParams(updatedParams);

    // Call categoryService with updated searchParams
    try {
      const response = await categoryService.getAvailableParamsByCurrentParams(
        updatedParams,
        newSelectedCustomFields
      );
      if (response.status === 200) {
        sortData(response.data);
        setCategoryData(response.data); // Update the checkboxes with filtered results
      }
    } catch (error) {
      console.error('Error filtering category data', error);
    }
  });

  const handleReset = () => {
    setSelectedParams({});
    setSelectedCustomFields([]);
    getAvailableParams();
  };

  const handleAccordionClick = () => {
    setIsCollapsedAll(!isCollapsedAll);
  };

  const handleSearch = async () => {
    try {
      
      const response = await categoryService.searchProductsByParams(selectedParams, selectedCustomFields);
      sendProducts(response.data.products);
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const sortData = (data) => {
    const keysToSort = ['MGN- Frame', 'MGN- HP'];
  
    keysToSort.forEach((key) => {
      if (data[key]) {
        data[key] = data[key].sort(naturalSort);
      }
    });
  
    return data;
  };

  const naturalSort = (a, b) => {
    const numA = parseInt(a.match(/\d+/)[0], 10);
    const numB = parseInt(b.match(/\d+/)[0], 10);
    return numA - numB;
  };

  const renderSidebarData = () => {
    if (categoryData) {
      return (
        <>
          <div className="d-flex gap-3 justify-content-between align-items-center px-2 my-3">
            <h5 className="m-0 font-weight-bold"> Filter by category </h5>
            <img
              title='Open/close all the categories'
              onClick={handleAccordionClick}
              src={multiChevronIconSvg}
              alt="Button multi chevron icon to open/close all the accordions"
              className="chevron-img"
              style={{
                transform: isCollapsedAll ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'transform 0.3s',
              }}
            />
          </div>
          {customFields.map((field, index) => (
            <React.Fragment key={index}>
              <SideBarAccordion
                name={field}
                subItems={categoryData[field] || []}
                collapseId={`collapse-${field}`}
                onCheckboxChange={handleCheckboxChange}
                selectedParams={selectedParams}
                isCollapsedAll={isCollapsedAll}
              />
              <div className="divider-side-bar my-2 w-100"></div>
            </React.Fragment>
          ))}

          <div className="buttons-side-bar my-3 d-flex justify-content-center align-items-center gap-5 px-3">
            <button
              disabled={Object.keys(selectedParams).length === 0}
              className="clear-btn-sidebard m-0 btn rounded w-100 rounded text-nowrap"
              type="reset"
              onClick={() => handleReset()}
            >
              Clear Filters
            </button>
            <button
              className="search-btn-sidebard btn m-0 rounded w-100"
              onClick={handleSearch}
              type="submit"
              disabled={Object.keys(selectedParams).length === 0 || selectedCustomFields.length === 0}
            >
              Search
            </button>
          </div>
        </>
      );
    }
  };

  return <>{renderSidebarData()}</>;
};

export default SideBar;
